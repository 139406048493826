<template lang="pug">
#holiday-warning
  .app-modal
    .app-modal-bg
    keep-alive 
      .modal-content(v-if="isHoliday")
        .modal-header
          h2 Do Not Start Kit 
        .modal-text
          icon#warning-icon(data="@icon/warning.svg")        
          p.text-margin Specimen stability may be at risk because your package may arrive when the laboratory is closed.
          p.text-margin(v-if="isFriday") Kit recommends starting your process <strong>tomorrow morning</strong> and shipping by <strong>1 PM</strong>.
          p.text-margin(v-else-if="isSaturday && numberOfHours < 48") Kit recommends starting your process <strong>tomorrow morning</strong>.
          p.text-margin(v-else) Kit recommends starting your process on the next business day, <strong>{{upsDay}}</strong>. 
        .modal-footer
            div
              button.button.action-btn(@click="$modal.close('HolidayWarning')") Close
              p.continue(v-if="allowToProceed" @click="close") Continue Anyway
      .modal-content(v-else)
        .modal-header
          h2 Drop at UPS by 1 PM Today
        .modal-text
          icon#warning-icon(data="@icon/warning.svg")       
          p.text-margin Please complete and drop off your Kit to your local UPS by <strong>1 PM today</strong>.
          p.text-margin(v-if="isFriday") If you are unable to do so, Kit recommends starting your process <strong>tomorrow morning</strong> and shipping by <strong>1 PM</strong>.
          p.text-margin(v-else) If you are unable to do so, Kit recommends starting your process tomorrow.
        .modal-footer
            div
              button.button.action-btn(@click="close") Continue
              p.continue(v-if="allowToProceed" @click="$modal.close('HolidayWarning')") Cancel
</template>

<script>
export default {
  props: {
    gotoNext: { type: Function, required: false },
    allowToProceed: { type: Boolean, required: true },
    upsDay: { type: String },
    isHoliday: { type: Boolean, default: false },
    isSaturday: { type: Boolean, default: false },
    isFriday: { type: Boolean, default: false },
    numberOfHours: { type: Number }
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {
    close,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function close() {
  this.$modal.close('HolidayWarning')
  this.gotoNext()
}
</script>
